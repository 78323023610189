<app-slide-in-right-modal
  [modalTitle]="
    (accountType$ | async)
      ? 'Assign RM to ' + (accountType$ | async | titlecase) + ' Account'
      : 'Assign RM to Account'
  "
>
  @if (!(isFetchingAllRMs$ | async) && !(isFetchingAccountRMs$ | async)) {
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >Search RM</app-input-label
      >

      <div class="relative rounded-md shadow-sm">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <span class="text-gray-500 sm:text-sm"
            ><img src="assets/images/svgs/search.svg" alt=""
          /></span>
        </div>
        <input
          type="search"
          name="search"
          (input)="onSearchChange($event)"
          id="rm-name"
          class="block w-full rounded-md border-0 py-[0.62rem] pl-11 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:text-sm sm:leading-6"
          placeholder="Search for an RM"
          aria-describedby="price-currency"
        />
      </div>

      <fieldset class="mt-8">
        <legend class="text-sm font-semibold leading-6 text-gray-900">
          Select an RM
        </legend>
        <div
          class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200"
        >
          @for (rm of allRMs$ | async; track $index) {
            <div class="relative flex items-start py-4">
              <div class="min-w-0 flex-1 text-sm leading-6">
                <label
                  [htmlFor]="rm.id"
                  class="select-none font-medium text-gray-900"
                  >{{ rm.first_name }} {{ rm.last_name }}</label
                >
              </div>
              <div class="ml-3 flex h-6 items-center">
                <input
                  [id]="rm.id"
                  name="rm-name"
                  [value]="rm.id"
                  type="radio"
                  (click)="onRmClick($event, rm)"
                  [checked]="rm.is_current_rm"
                  class="h-4 w-4 border-gray-300 text-mango focus:ring-mango"
                />
              </div>
            </div>
          }
        </div>
      </fieldset>

      <div *ngIf="filteredResultsCount === 0">No results for this search</div>
    </div>
  } @else {
    <div class="flex flex-col">
      <div *ngFor="let in of [0, 1, 2, 3, 4, 5]; let i = index">
        <ngx-skeleton-loader
          [theme]="{ height: '3rem', width: '100%' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  }
</app-slide-in-right-modal>

<ng-container *ngIf="rmInfo$ | async as rmInfo">
  <app-confirm-dialog
    [isOpen]="(isDialogOpen$ | async)!!"
    [confirmQuestion]="
      'Are you sure you want to assign this RM: \'' +
      rmInfo.first_name +
      ' ' +
      rmInfo.last_name +
      '\' to the account?'
    "
    confirmText="Yes, Assign RM"
    (dialogAction)="assignRM(rmInfo)"
    [isDialogLoading]="(isDialogLoading$ | async)!!"
    dialogLoadingText="Assigning RM..."
    (close)="closeDialog()"
  >
  </app-confirm-dialog>
</ng-container>
